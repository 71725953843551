import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './transition.scss';
import App from './App';

ReactDOM.render(
    // remove qore
    <App/>
, document.getElementById('root'));
